import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import Seo from "../components/seo";

const PostTemplate = ({ data }) => {
  const post = data.wpPost;

  return (
    <>
      <Seo title={post.seo?.title} description={post.seo?.metaDesc} />

      <div className="prose prose-lg max-w-5xl">
        <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8">
          <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
        </div>
        <div className="max-w-xl mx-auto">
          <img
            className="w-full p-4 h-auto"
            src={post.featuredImage.node.localFile.publicURL}
            alt={post.featuredImage.node.title}
          />
        </div>
        <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8">
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          {post.acf &&
            post.acf.page_builder_post &&
            post.acf.page_builder_post.map((layout, i) => {
              if (layout.__typename === `WordPressAcf_imageGallery`) {
                return (
                  <div key={`${i} image-gallery`}>
                    <h2>ACF Image Gallery</h2>
                    {layout.pictures.map(({ picture }) => {
                      const img =
                        picture.localFile.childImageSharp.gatsbyImageData;
                      return (
                        <GatsbyImage
                          alt="Gallery image"
                          image={img}
                          css={{ marginBottom: "1rem" }}
                          key={img.src}
                        />
                      );
                    })}
                  </div>
                );
              }
              if (layout.__typename === `WordPressAcf_post_photo`) {
                const img =
                  layout.photo.localFile.childImageSharp.gatsbyImageData;
                return (
                  <div key={`${i}-photo`}>
                    <h2>ACF Post Photo</h2>
                    <GatsbyImage
                      alt="Photo"
                      image={img}
                      css={{ marginBottom: "1rem" }}
                      src={img.src}
                    />
                  </div>
                );
              }
              return null;
            })}
        </div>
        <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8">
          <p className="my-8">
            <Link to="/blog/">Back to blog</Link>
          </p>
        </div>
      </div>
    </>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          title
          localFile {
            publicURL
          }
        }
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
